import * as React from 'react'
import loadable from '@loadable/component'

const Button = loadable(() => import('/src/components/Basic/Buttons/Button'))
const CtaContainer = loadable(() =>
    import('/src/components/Basic/CtaContainer')
)
const FeatureRadio = loadable(() => import('/src/components/Form/FeatureRadio'))
const FieldSet = loadable(() => import('/src/components/Form/FieldSet'))
const FieldSpacer = loadable(() => import('/src/components/Form/FieldSpacer'))
const LargeCard = loadable(() => import('/src/components/Cards/LargeCard'))

const StepOutgoings = () => {
    const identifier = 'outgoings'

    return (
        <LargeCard className={'slick-card'}>
            <FieldSet
                align={'centered'}
                legend={'What are your monthly household outgoings?'}
                groupName={identifier}
            >
                <FieldSpacer>
                    <FeatureRadio
                        label={'£100 - £500'}
                        groupName={identifier}
                        name={`${identifier}1`}
                        value={'250'}
                        icon={'family'}
                    />
                </FieldSpacer>

                <FieldSpacer>
                    <FeatureRadio
                        label={'£500 - £1,000'}
                        groupName={identifier}
                        name={`${identifier}2`}
                        value={'750'}
                        icon={'home'}
                    />
                </FieldSpacer>

                <FieldSpacer>
                    <FeatureRadio
                        label={'£1,000 - £2,000'}
                        groupName={identifier}
                        name={`${identifier}3`}
                        value={'1500'}
                        icon={'family'}
                    />
                </FieldSpacer>

                <FieldSpacer>
                    <FeatureRadio
                        label={'£2,000+'}
                        groupName={identifier}
                        name={`${identifier}4`}
                        value={'2000'}
                        icon={'home'}
                    />
                </FieldSpacer>
            </FieldSet>

            <CtaContainer align={'centered'}>
                <Button type={'submit'} size={'lg'} icon={'chevron'}>
                    Next
                </Button>
            </CtaContainer>
        </LargeCard>
    )
}

export default StepOutgoings
